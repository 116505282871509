
$mainColorPrimary200: #A8E7F5;
$mainColorPrimary300: #7ED957;
$mainColorPrimary500: #ffffff;
$mainColorPrimary600: #1F4A31;
$mainColorPrimary650: #1F560F;
$mainColorPrimary700: #164775;
$mainColorPrimary800: #101728;

$mainColorSecondary100: #FDECD6;
$mainColorSecondary200: #FCD5AD;
$mainColorSecondary300: #F8B683;
$mainColorSecondary400: #F19764;
$mainColorSecondary500: #E96933;
$mainColorSecondary600: #EC6E1A;

$neutralColorF2F1F6: #F2F1F6;
$neutralColor100: #FFFFFF;
$neutralColor200: #E2E3E3;
$neutralColor300: #C6C6C8;
$neutralColor400: #A9AAAC;
$neutralColor500: #8C8E90;
$neutralColor600: #6F7174;
$neutralColor700: #535559;
$neutralColor800: #36383D;
$neutralColor900: #2A2B2D;

$helpColorInfo: #0095FF;
$helpColorConfirm: #4AC966;
$helpColorError: #FF3D71;

$px12-to-rem: 0.75rem;
$px14-to-rem: 0.87rem;
$px18-to-rem: 1.12rem;
$px20-to-rem: 1.25rem;
$px24-to-rem: 1.5rem;

/* breakpoints */
$xsBreakpoint: 576px;
$smBreakpoint: 576px;
$mdBreakpoint: 768px;
$lgBreakpoint: 992px;
$xlBreakpoint: 1200px;
$xxlBreakpoint: 1400px;

/*
// AZUL
// $primarycolor:  #1F3A60;
// var(--secondary-color): #2E5077;
// $tertiarycolor:  #5A7290;
// $fontcolor: #ffffff;
// $fontcolor2: #0099ff;
// $buttoncolor: #0099ff;

// AZUL PASTEL
// $primarycolor: #A9CCE3;
// var(--secondary-color): #D6EAF8;
// $tertiarycolor:  #E5E7E9;
// $fontcolor: #4A4A4A;
// $fontcolor2: #2874A6;
// $buttoncolor: #5DACE2;
*/

/* VERDE */
$primarycolor: #30706F;
$secundarycolor: #f2f2f3;
$tertiarycolor: #ffffff;
$fontcolor: #61565C;
$fontcolor2: #f3f3f4;
$buttoncolor: #E96933;



/*
// $primarycolor: #000000;
// var(--secondary-color): #ff9900;
// $tertiarycolor:  #F5EAD1;
// $fontcolor: #4A4A4A;
// $fontcolor2: #ff9900;
// $buttoncolor: #515541;
*/

:root {
    --primary-color: #30706F;
    --secondary-color: #fbfbfb;
    --tertiary-color: #ffffff;
    --font-color: #61565C;
    --font-color2: #f3f3f4;
    --button-color: #E96933;
    --input-color: #f2f2f3;
    --font-family: 'Inter', sans-serif;
}
