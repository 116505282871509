@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "./sass_variables.scss";
@import "./app/_core/scss/themes.scss";
@import "./app/_core/scss/mixins.scss";



// body {
//   margin: 0px;
//   padding: 0px;
// }

.titlebar {
  position: fixed;
  z-index: 2;
  width: 100%;
  top: 0;
}

.container-pad {
  height: calc(100vh - 64px);
}

.side {
  width: 250px;
  margin-top: -7px;
}

.content {
  height: calc(100vw - 250px);
  padding-top: 0px;
  padding-left: $px20-to-rem;
  padding-right: $px20-to-rem;
  padding-bottom: $px20-to-rem;
  background-color: white;
}

.box {
  padding-top: 64px;
}

// .example-spacer {
//   flex: 1 1 auto;
// }

// .titulo {
//   padding-left: 5px;
// }

// .load {
//   margin-left: -20px;
//   margin-right: -20px;
//   margin-bottom: 20px;
// }

// .data-table {
//   width: 100%;
// }

// .page-title {
//   font-size: x-large;
//   text-align: left;
// }

// .search {
//   width: 400px;
// }

// .mat-grid-tile .mat-figure {
//   justify-content: flex-start !important ;
// }

// .textColorGrey{
//   color: #838787;
// }

// .yourCodeText{
//   font-size: 1.5em;
// }

// .premiosResg{
//   font-size: 1.1em;
// }

// .btnsinc {
//   background: #808080;
// }

.btnResgate {
  background: #F19E47;
  color: white;
  border-style: none;
  text-transform: uppercase;
  border-radius: 5px;
  box-shadow: 0px 2px 2px gray;
  min-height: 5vh;
}

// .btnOkCard {
//   background: #f96928;
// }

// .swiper-slide {
//   display: block;
//   height: 100%;
//   min-height: 100%;
// }

// .slide-background {
//   border-color: transparent;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   width: 100%;
//   height: 100%;
// }

// .sliderArrowLeft {
//   background: #FFFFFF;
//   position: absolute;
//   left: 0px;
//   top: 50%;
// }

// .sliderArrowRight {
//   background: #FFFFFF;
//   position: absolute;
//   right: 0px;
//   top: 50%;
// }

// .beginButton {
//   background: #0e4377;
//   position: absolute;
//   bottom: 0px;
//   width: 100%;
// }

// .spacer10px {
//   width: 100%;
//   height: 10px;
// }

// .spacer20px {
//   width: 100%;
//   height: 20px;
// }

// .spinnerDiv {
//   background-color: rgba(0, 0, 0, 0.4);
// }

// .spinnerCenter {
//   position: absolute;
//   top: 50%;
//   right: 50%;

// }

.underLined {
  text-decoration: underline;
}

.centerInSpace{
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
}

.spinnerItem{
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.actionBar{
  width: 100%;
  height: 10%;
  padding-left: 5%;
  padding-top: 2%;
  padding-right: 5%;
  box-shadow: 0px 2px 2px gray;
}

.btnWhite{
  background: #FFFFFF;
  color: #000000;
  width: 100%;
  border-style: none;
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  min-height: 5vh;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: $px20-to-rem;
  color: $neutralColor600;
}

.btnBlack{
  background: #000000;
  color: #ffffff;
  width: 100%;
  border-style: none;
  text-transform: uppercase;
  border-radius: 5px;
  box-shadow: 0px 2px 2px gray;
  min-height: 5vh;
}

.alarmPrompt{
  position: fixed;
  background-color: white;

  width: 50%;
  height: auto;
  right: 25%;
  top: 10%;
  padding: 1%;

  border: 1px solid gray;
  border-radius: 5px;
}

/**
LOADER
*/
.infoLoader {
border: 3px solid #f3f3f3;
border-radius: 50%;
border-top: 3px solid #3498db;
width: 24px;
height: 24px;
-webkit-animation: spin 2s linear infinite; /* Safari */
animation: spin 2s linear infinite;
/* to center the loader */
position: relative;
left: 50%;
top: 50%;

}

/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
/*
end LOADER
*/

// #######################################################################################################################################
// ####################################################### NEW LAYOUT ####################################################################
// #######################################################################################################################################


.body1Regular{

  /* Body 1/Regular */
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.body1Semibold{

  /* Body 1/Semibold */
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.body1Bold{

  /* Body 1/Bold */
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.textBig{
  font-size: 16px;
}

.textMedium{
  font-size: 14px;
}

.textSmall{
  font-size: 12px;
}

.headlineRegular{

  /* Headline / Regular */
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.headlineBold{

  /* Headline/Bold */
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* or 38px */
}

.neutralColor100{
  color: $neutralColor100;
}

.neutralColor300{
  color: $neutralColor300;
}

.neutralColor500{
  color: $neutralColor500;
}

.neutralColor600{

  /* Neutral Colors / 600 */
  color: $neutralColor600;
}

.neutralColor700 {
  color: $neutralColor700;
}

.neutralColor800{

  /* Neutral Colors / 800 */
  color: $neutralColor800;
}

.mainColorPrimary200{
  color: $mainColorPrimary200;
}

.mainColorPrimary300{
  color: $mainColorPrimary300;
}

.mainColorPrimary500{
  color: $mainColorPrimary500;
}

.mainColorPrimary600{
  color: $mainColorPrimary600;
}

.mainColorSecondary500{
  color: $mainColorSecondary500;
}

.mainColorSecondary600{
  color: $mainColorSecondary600;
}

.helpColorInfo{
  color: $helpColorInfo;
}

.helpColorConfirm{
  color: $helpColorConfirm;
}

.helpColorError{
  color: $helpColorError;
}

.colorGradientOrange45{
  /* Gradients/Orange/45º */
  color: linear-gradient(46.94deg, #E85E51 0%, #E96933 47.43%, #EC9032 99.9%);
}

.backgroundMainColorPrimary500{
  background-color: $mainColorPrimary500;
}

.backgroundMainColorPrimary600{
  background-color: $mainColorPrimary600;
}

.backgroundMainColorPrimary700{
  background-color: $mainColorPrimary700;
}

.backgroundMainColorPrimary800{
  background-color: $mainColorPrimary800;
}

.backgroundMainColorSecondary100{
  /* Main Colors/Secondary/100 */
  background-color: $mainColorSecondary100;
}

.backgroundMainColorSecondary500{
  background-color: $mainColorSecondary500;
}

.backgroundNeutralColor100{
  background-color: $neutralColor100;
}

.backgroundNeutralColor300{
  background-color: $neutralColor300;
}

.backgroundGradientOrange45{

  /* Gradients/Orange/45º */
  background: linear-gradient(46.94deg, #E85E51 0%, #E96933 47.43%, #EC9032 99.9%);
}

.backgroundHelpColorConfirm{
  background-color: $helpColorConfirm;
}

.backgroundTransparent{
  background-color: transparent;
}

.inputEnabled{
  /* Text field/Enabled/Light */

  padding: 8px;

  /* Body 1/Regular */
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  /* Neutral Colors / 500 */
  color: $neutralColor700;
}

.inputEnabled:focus{

  /* Neutral Colors / 700 */
  color: $neutralColor800;
}

.inputEnabled:disabled{
  background-color: $neutralColor300;
}

.inputLight{

  /* Neutral Colors / 100 */
  background: $neutralColor100;

  /* Neutral Colors / 300 */
  border: 1px solid $neutralColor300;
  border-radius: 4px;
}

.dropdownBox{
  box-shadow: 5px 5px 20px rgba(17, 51, 94, 0.2);
}

.dropdownBtn{
  width: 100%;
  background: transparent;
  border: none;
}

.dropdownBtn::after{
  display: none;
  visibility: hidden;
}

.dropdownItem{
  background: $neutralColor100;

  /* Body 1/Semibold */

  color: $mainColorPrimary500;

  width: 100%;

}

.form-switch .form-check-input{

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus{

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.inputSwitch{
  background-color: $neutralColor400;
  border: none;
}

.inputSwitch:focus{

  box-shadow: none;
  color: $mainColorSecondary200;
  border-color: $mainColorSecondary200;
}

.inputSwitch:checked{
  background-color: #00D68F;

  border: none;
}

.inputSwitch:checked:focus{

  border: none;
  box-shadow: none;
}

.btnContainedOrange, .btnOutlinedOrange ,.btnTextOrange{

  padding: 0.5rem 1rem;

  /* Buttons/Button 1/Button 1 Bold */
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: $px20-to-rem;
  /* identical to box height, or 125% */
}

.btnContainedOrange{

    /* Main Colors/Secondary/► 500 */
    background-color: var(--button-color);

    /* Main Colors/Secondary/► 500 */
    border: 1px solid var(--button-color);
    box-sizing: border-box;

    /* Shadows/Enabled */
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3);
    border-radius: 4px;

    /* Neutral Colors / 100 */
    color: $neutralColor100;
}

.btnContainedOrange:hover{
 transform: scale(1.05);
  /* Main Colors/Secondary/► 500 */
}

.btnContainedOrange:focus{
  box-shadow: none;
}

.btnContainedOrange:disabled{

  /* Neutral Colors / 300 */
  background: $neutralColor300;

  /* Neutral Colors / 300 */
  border: 1px solid $neutralColor300;

  /* Neutral Colors / 400 */
  color: $neutralColor400;

  box-shadow: none;
}

.btnTextOrange{

  border: none;
  border-radius: 4px;
  background-color: transparent;

  /* Main Colors/Secondary/► 500 */

  color: $mainColorSecondary500;
}

.btnTextOrange:hover{
  background-color: $mainColorSecondary200;
}

.btnTextOrange:enabled:focus{
  background-color: $mainColorSecondary300;
}

.btnTextOrange:disabled{

  /* Neutral Colors / 400 */
  color: $neutralColor400;

}

.btnTextOrange:disabled:focus{

  background-color: transparent;

}

.btnOutlinedOrange{

  /* Neutral Colors / 100 */
  background-color: var(--button-color);

  /* Main Colors/Secondary/400 */
  border: 1px solid var(--button-color);
  box-sizing: border-box;
  border-radius: 4px;

  /* Main Colors/Secondary/► 500 */
  color: var(--secondary-color);
}


.btnOutlinedOrange:focus{
  transform: scale(1.05);
}

.btnSmall{
  min-width: 5rem;
}

.btnMedium{
  min-width: 10rem;
}

.btnBig{
  min-width: 15rem;
}

.btnMobile{
  width: 100%;
}

.autoLayout{

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexColumnLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.insideAutoLayout{
  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.marginSmall{
  margin: 0.5rem;
}

.marginMedium{
  margin: 1rem;
}

.marginBig{
  margin: 2rem;
}

.marginVerticalSmall{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.marginVerticalMedium{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.marginVerticalBig{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.marginTopSmall{
  margin-top: 0.5rem;
}

.marginTopMedium{
  margin-top: 1rem;
}

.marginTopBig{
  margin-top: 2rem;
}

.marginBottomSmall{
  margin-bottom: 0.5rem;
}

.marginBottomMedium{
  margin-bottom: 1rem;
}

.marginBottomBig{
  margin-bottom: 2rem;
}

.marginVertical5px{
  margin-top: 5px;
  margin-bottom: 5px;
}



.marginHorizontalSmall{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.marginHorizontalMedium{
  margin-left: 1rem;
  margin-right: 1rem;
}

.marginHorizontalBig{
  margin-left: 2rem;
  margin-right: 2rem;
}

.paddingSmall{
  padding: 0.5rem;
}

.paddingMedium{
  padding: 1rem;
}

.paddingBig{
  padding: 2rem;

}
.marginHorizontal10px {
  margin-left: 10px;
  margin-right: 10px;
}

.noBottomPadding {
  padding-bottom: 0px;
}

.paddingVerticalSmall{
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.paddingVerticalMedium{
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.paddingVerticalBig{
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.paddingHorizontalSmall{
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.paddingHorizontalMedium{
  padding-right: 1rem;
  padding-left: 1rem;
}

.paddingHorizontalBig{
  padding-right: 2rem;
  padding-left: 2rem;
}

.cursorPointer{
  cursor: pointer;
}

.listDivisorNeutral{
  border-bottom: 1px solid $neutralColor400;
}

.listDivisorPrimary{
  border-bottom: 1px solid $mainColorPrimary500;
}

.listDivisorSecondary{
  border-bottom: 1px solid $mainColorSecondary500;
}

.borderNone{
  border: none;
}

.borderMainColorSecondary500{
  border: 1px solid $mainColorSecondary500;
  box-sizing: border-box;
}

.borderSmallMainColorPrimary500{
  border: 1px solid $mainColorPrimary500;
}

.borderSmallNeutralColor400{
  border: 1px solid $neutralColor400;
}

.borderSmallRightNeutralColor400{
  border-right: 1px solid $neutralColor400;
}

.borderTopNeutralColor200{
  border-top: 1px solid $neutralColor200;
}

.borderBottomNeutralColor200{
  border-bottom: 1px solid $neutralColor200;
}

.borderSidesNeutralColor200{
  border-right: 1px solid $neutralColor200;
  border-left: 1px solid $neutralColor200;
}

.borderRadiusSmall{
  border-radius: 4px;
}

.borderRadiusMedium{
  border-radius: 8px;
}

.borderRadiusBig{
  border-radius: 16px;
}

.borderRadiusTopSmall{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.borderRadiusTopMedium{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.borderRadiusTopBig{
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.borderRadiusBottomSmall{
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.borderRadiusBottomMedium{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.borderRadiusBottomBig{
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.borderRadiusRightSmall{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.borderRadiusRightMedium{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.borderRadiusRightBig{
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.borderRadiusLeftSmall{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.borderRadiusLeftMedium{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.borderRadiusLeftBig{
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.shadowBox{
  box-shadow: 5px 5px 20px rgba(17, 51, 94, 0.2);
}

.selectedCardChoice {
  background-color: var(--button-color);
}

.scrollVertical{
  overflow-y: scroll;
}

.scrollVertical::-webkit-scrollbar{
  width: 0.5rem;
  padding: 0.5rem;
}

.scrollVertical::-webkit-scrollbar-track{
  background: transparent;
}

.scrollVertical::-webkit-scrollbar-thumb{
  border-radius: 16px;
  background: $mainColorPrimary500;
}

// ngb-datepicker {
// 	border: 1px solid var(--bs-border-color);
// 	border-radius: 0.25rem;
// 	display: inline-block;

// 	&-month {
// 		pointer-events: auto;
// 	}

// 	&.dropdown-menu {
// 		padding: 0;
// 	}

// 	&.disabled {
// 		.ngb-dp-weekday,
// 		.ngb-dp-week-number,
// 		.ngb-dp-month-name {
// 			color: var(--bs-text-muted);
// 		}
// 	}
// }

// .ngb-dp {
// 	&-body {
// 		z-index: 1055;
// 	}

// 	&-header {
// 		border-bottom: 0;
// 		border-radius: 0.25rem 0.25rem 0 0;
// 		padding-top: 0.25rem;
// 		background-color: var(--bs-light);
// 	}

// 	&-months {
// 		display: flex;
// 	}

// 	&-month {
// 		pointer-events: none;

// 		&-name {
// 			font-size: larger;
// 			height: 2rem;
// 			line-height: 2rem;
// 			text-align: center;
// 			background-color: var(--bs-light);
// 		}

// 		& + & {
// 			.ngb-dp-month-name,
// 			.ngb-dp-week {
// 				padding-left: 1rem;
// 			}
// 		}

// 		&:last-child .ngb-dp-week {
// 			padding-right: 0.25rem;
// 		}

// 		&:first-child .ngb-dp-week {
// 			padding-left: 0.25rem;
// 		}

// 		.ngb-dp-week:last-child {
// 			padding-bottom: 0.25rem;
// 		}
// 	}
// }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }




.close-button-global {
  position: absolute;
  top: 1%;
  right: 1%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #F86262;
  font-weight: bold;
  font-size: 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 4px;

  &::before {
    content: "\f00d";  // Código unicode para o ícone "fa-times"
    font-family: "Font Awesome 5 Free";  // Certifique-se de que está carregando a fonte correta
    font-weight: 900;  // Necessário para ícones sólidos
  }
}

.title-modal-global{
  font-size: 18px;
  font-weight: 500;
  @include themify($themes) {
    color: themed('textColor');
  }
}

.title-modal-input{
  font-size: 14px;
  font-weight: 400;
  @include themify($themes) {
    color: themed('textColor');
  }
}

.btn-save-global{
  background-color: var(--button-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  width: 90%;
  margin-top: 1rem;
}

.modal-content{
  @include themify($themes) {
    color: themed('textColor');
    background-color: themed('backgroundColor');
  }
}

.input-global{
  @include themify($themes) {
    color: themed('colorInput');
    background-color: themed('backgroundInput');
  }
  width: 90%;
  border: none;
  height: 2rem;
  min-height: 2rem;
  border-radius: 4px;
  font-style: italic;
  margin: 0.5rem;
}

.select-global{
  @include themify($themes) {
    color: themed('colorInput');
    background-color: themed('backgroundInput');
  }
  width: 90%;
  border: none;
  height: 2rem;
  min-height: 2rem;
  border-radius: 4px;
  font-style: italic;
  margin: 0.5rem;
}



